<template>
  <Disclosure as="nav" class="bg-zinc-800 noPrint" v-slot="{ open }">
    <div class="mx-auto max-w-[85rem] px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 items-center justify-between">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <img
              class="h-8 w-auto"
              src="../assets/images/tweet.jpeg"
              alt="Tweet Gen"
            />
          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.current
                    ? 'bg-red-600 text-white'
                    : 'text-white hover:bg-red-600 hover:text-white',
                  'inline-flex items-center rounded-md py-2 px-3 text-sm font-medium',
                ]"
                :aria-current="item.current ? 'page' : undefined"
                >{{ item.name }}</router-link
              >
              <Menu as="div" class="relative ml-3">
                <div>
                  <MenuButton
                    class="text-white inline-flex items-center rounded-md py-2 px-3 text-sm font-medium"
                  >
                    <span class="absolute -inset-1.5" />
                    <p></p>
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-skylrsOffYellow py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem
                      v-slot="{ active }"
                      v-for="item in whatis"
                      :key="item.name"
                    >
                      <router-link
                        :to="item.href"
                        :class="[
                          active ? 'bg-red-600' : '',
                          'block px-4 py-2 text-sm text-white',
                        ]"
                        >{{ item.name }}</router-link
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
        <div class="hidden sm:ml-6 sm:block">
          <div v-if="loggedUser" class="flex items-center">
            <Menu as="div" v-if="getUser.role === 1" class="relative ml-3">
              <div>
                <MenuButton
                  class="text-white inline-flex items-center rounded-md py-2 px-3 text-sm font-medium"
                >
                  <span class="absolute -inset-1.5" />
                  <p>Admin</p>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-skylrsOffYellow py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem
                    v-slot="{ active }"
                    v-for="item in admin"
                    :key="item.name"
                  >
                    <router-link
                      :to="item.href"
                      :class="[
                        active ? 'bg-red-600' : '',
                        'block px-4 py-2 text-sm text-white',
                      ]"
                      >{{ item.name }}</router-link
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span class="absolute -inset-1.5" />
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="h-8 w-8 rounded-full"
                    :src="getUser.image"
                    alt=""
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-skylrsRed py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem v-slot="{ active }">
                    <router-link
                      :to="'/profile'"
                      :class="[
                        active ? 'bg-red-600' : '',
                        'block px-4 py-2 text-sm text-white',
                      ]"
                      >Your Profile</router-link
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-red-600' : '',
                        'block px-4 py-2 text-sm text-white',
                      ]"
                      >Settings</a
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <span
                      @click="this.signOut"
                      :class="[
                        active ? 'bg-red-600' : '',
                        'block px-4 py-2 text-sm text-white',
                      ]"
                      >Sign out</span
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div class="flex space-x-4" v-if="!loggedUser">
            <router-link
              v-for="item in auth"
              :key="item.name"
              :to="item.href"
              :class="[
                item.current
                  ? 'bg-red-600 text-white'
                  : 'text-white hover:bg-red-600 hover:text-white',
                'inline-flex items-center rounded-md py-2 px-3 text-sm font-medium',
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}</router-link
            >
          </div>
        </div>
        <div class="-mr-2 flex sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-300 hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <span class="absolute -inset-0.5" />
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
        <DisclosureButton
          v-for="item in navigation"
          :key="item.name"
          as="a"
          :href="item.href"
          :class="[
            item.current
              ? 'bg-red-600 text-white'
              : 'text-gray-300 hover:bg-red-600 hover:text-white',
            'block rounded-md py-2 px-3 text-base font-medium',
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}
        </DisclosureButton>
      </div>
      <div class="space-y-1 px-2">
        <DisclosureButton
          v-for="item in whatis"
          :key="item.name"
          as="a"
          :href="item.href"
          class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-red-600 hover:text-white"
          >{{ item.name }}
        </DisclosureButton>
      </div>
      <div class="border-t border-skylrsYellow pb-3 pt-4">
        <div class="flex items-center px-5" v-if="loggedUser">
          <div class="flex-shrink-0">
            <img
              class="h-10 w-10 rounded-full"
              :src="getUser.image"
              alt=""
            />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-white">
              {{ getUser.first_name }} {{ getUser.last_name }}
            </div>
            <div class="text-sm font-medium text-gray-300">
              {{ getUser.email }}
            </div>
          </div>
        </div>

        <div class="mt-3 space-y-1 px-2" v-if="loggedUser">
          <DisclosureButton
            v-for="item in userNavigation"
            :key="item.name"
            as="a"
            :href="item.href"
            class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-red-600 hover:text-white"
            >{{ item.name }}
          </DisclosureButton>
        </div>
        <div class="mt-3 space-y-1 px-2" v-else>
          <DisclosureButton
            v-for="item in auth"
            :key="item.name"
            as="a"
            :href="item.href"
            class="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-red-600 hover:text-white"
            >{{ item.name }}
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { XMarkIcon, Bars3Icon } from "@heroicons/vue/20/solid";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    Disclosure,
    DisclosurePanel,
    DisclosureButton,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    XMarkIcon,
    Bars3Icon,
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "loggedUser",
      "getUser",
      // ...
    ]),
  },
  methods: {
    ...mapMutations(["SIGN_OUT"]),
    signOut() {
      this.SIGN_OUT();
      this.$router.push("/");
    },
  },
  data() {
    return {
      navigation: [
      ],
      whatis: [
      ],
      auth: [
      ],
      userNavigation: [
      ],
      admin: [
      ],
    };
  },
};
</script>
