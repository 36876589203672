<template>
  <div class="overflow-hidden bg-zinc-950 py-32">
    <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
        <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
          <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl" id="letscreate">Create a new tweet</h2>
          <p class="mt-6 text-md leading-8 text-white">
            Welcome to the future of social media mastery!
            Introducing Tweet-Gen, where artificial intelligence meets creativity. Simply
            input your desired topic, and watch as our neural networks weave captivating
            posts for platforms like X and Instagram. Let the AI do the heavy lifting while
            you bask in the glow of viral content. The future is now, and it’s powered by algorithms!
          </p>
          <div class="mt-3">
            <label for="content" class="mt-10 text-md text-white"
              >What's the topic, human?</label
            >
            <div class="mt-2">
              <input
                type="text"
                v-model="recipe"
                name="content"
                id="content"
                class="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Give me a topic"
              />
            </div>

            <button
              v-if="loading === false"
              class="mt-6 bg-sky-400 hover:bg-sky-300 leading-6 text-sm shadow rounded-md text-white py-2 px-4 rounded"
              @click="this.getRecipe"
            >
              Lets fetch a tweet
            </button>
            <button
              v-else
              type="button"
              class="mt-6 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-sky-400 hover:bg-sky-200 transition ease-in-out duration-150 cursor-not-allowed"
              disabled=""
            >
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Fetching...
            </button>
          </div>
        </div>
        <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
          <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
            <img src="../assets/images/tweet.jpeg" alt="" class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
          </div>
          <div class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
            <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
              <img src="../assets/images/cyper.jpeg" alt="" class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
            </div>
            <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img src="../assets/images/cyber2.jpeg" alt="" class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
            </div>
            <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
              <img src="../assets/images/cyber3.jpeg" alt="" class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-zinc-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-sky-400">
                  <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-white">{{recipe}}</DialogTitle>
                  <div class="mt-2">
                    <div>
                        <div class="mt-2">
                          <p class="text-md text-white">{{callback}}</p>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button @click="this.closeModel" class="inline-flex w-full justify-center rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Close</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {mapMutations} from "vuex";
import {CheckIcon} from "@heroicons/vue/24/outline";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  name: "HomeView",
  components: {
    CheckIcon,
    Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot
  },
  data() {
    return {
      recipe: "",
      callback: "",
      api: process.env.VUE_APP_API_ENDPOINT,
      loading: false,
      recipe_loaded: false,
      open: false
    };
  },
  methods: {
    ...mapMutations([
      'RECIPE'
    ]),
    closeModel(){
      this.recipe = ""
      this.open = false
    },
    getRecipe() {
      let data = {
        content: this.recipe
      };
      this.loading = true;
      this.axios
        .post(
          this.api + "/recipes/twitter_ask",
          data
        )
        .then((response) => {
          this.callback = response.data.data
          this.open = true
          this.loading = false
        });
    },
  },
};
</script>

